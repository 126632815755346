<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-3xl">
      <div class="w-full flex flex-col gap-4">
        <span class="text-2xl font-bold">Plans</span>

        <div>
          <div class="flex items-center">
            <vs-input
              v-model="input"
              placeholder="Product or Store name"
              class="w-full"
              block
              v-on:keyup.enter="getAllPlans"
            ></vs-input>

            <vs-button
              class="w-10 h-10 text-center"
              block
              icon
              @click="getAllPlans"
            >
              <i class="bx bx-search" />
            </vs-button>
          </div>

          <div class="flex gap-4">
            <vs-checkbox v-model="store"> Store</vs-checkbox>
            <vs-checkbox v-model="product"> Product</vs-checkbox>
            <vs-checkbox v-model="oldPlans"> Old Plans</vs-checkbox>
          </div>
        </div>

        <PlansTable :plans="plans" />
      </div>
    </div>
  </div>
</template>

<script>
import { getPlans } from "@/services/ApiService";
import PlansTable from "@/views/admin/plan/PlansTable";

export default {
  name: "Plans",
  components: { PlansTable },
  data() {
    return {
      plans: undefined,
      input: undefined,
      store: false,
      product: false,
      oldPlans: false,
    };
  },
  methods: {
    async getAllPlans() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      this.plans = await getPlans(
        1,
        this.input,
        this.store,
        this.product,
        this.oldPlans
      ).catch((err) => {
        this.$vs.notification({
          color: "danger",
          name: "Error",
          text: err,
        });
      });

      loading.close();
    },
  },
  watch: {
    store(val) {
      if (this.input) this.product = !val;

      if (val) {
        this.getAllPlans();
      }
    },
    product(val) {
      if (this.input) this.store = !val;

      if (val) {
        this.getAllPlans();
      }
    },
    oldPlans() {
      this.getAllPlans();
    },
    input(val) {
      if (!val) {
        this.store = false;
        this.product = false;
        this.getAllPlans();
      }
    },
  },
  created() {
    this.getAllPlans();
  },
};
</script>

<style scoped>

</style>
