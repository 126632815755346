<template>
  <div class="w-full">
    <vs-table class="w-auto relative" ref="table" v-model="selected">
      <template #thead>
        <vs-tr>
          <vs-th class="!w-2">
            <i class="bx bx-check" />
          </vs-th>
          <vs-th class="!w-1/5"> Product </vs-th>
          <vs-th> Store </vs-th>
          <vs-th> Duration </vs-th>
          <vs-th> Price </vs-th>
          <vs-th> Slot </vs-th>
          <vs-th> Created At</vs-th>
        </vs-tr>
      </template>
      <template #tbody v-if="plans">
        <vs-tr
          :key="i"
          v-for="(pl, i) in plans.docs"
          :data="pl"
          class="cursor-pointer"
          :is-selected="selected === pl"
        >
          <vs-td>
            <i v-if="!checkActive(pl)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>
          <vs-td>
            {{
              pl.product &&
              (pl.product.name.length > NAME_MAX_LENGTH
                ? pl.product.name.substring(0, NAME_MAX_LENGTH) + "..."
                : pl.product.name)
            }}
          </vs-td>
          <vs-td> {{ pl.store.name }}</vs-td>
          <vs-td> {{ pl.name }}</vs-td>
          <vs-td> {{ pl.price }} $</vs-td>
          <vs-td> {{ pl.fulfilledSlots }}/{{ pl.slot }}</vs-td>
          <vs-td>
            {{ pl.createdAt | formatTimestamp }}
          </vs-td>
        </vs-tr>
      </template>
      <template #footer v-if="plans">
        <vs-pagination v-model="page" :length="plans.totalPages" />
      </template>
    </vs-table>
  </div>
</template>

<script>
import { getPlans } from "@/services/ApiService";

export default {
  name: "PlansTable",
  props: {
    plans: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    page: 1,
    selected: null,
    NAME_MAX_LENGTH: 25,
  }),
  methods: {
    async getPlans() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      this.plans = await getPlans(
        this.page,
        this.input,
        this.store,
        this.product,
        this.oldPlans
      ).catch((err) => {
        this.$vs.notification({
          color: "danger",
          name: "Error",
          text: err,
        });
      });

      loading.close();
    },
    checkActive(plan) {
      return plan.product.deleted || plan.active;
    },
  },
  watch: {
    page() {
      this.getPlans();
    },
    selected: {
      handler() {
        const id = this.selected._id;
        this.$router.push({
          name: "plans-update",
          params: { id },
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
